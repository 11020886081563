<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Alerts</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/alerts/create">
          <v-icon left dark>mdi-plus</v-icon>Add New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.NextDate="{ item }"
                >{{ item.NextDate + " " + item.NextTime }}
              </template>
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.FrqncyType="{ item }">
                {{ mappedFrqncyType(item.FrqncyType) }}
              </template>

              <template v-slot:item.Active="{ item }">
                <v-btn class="btnLink" color="accent" text>{{
                  item.Active == 1 ? "Yes" : "No"
                }}</v-btn>
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/alerts/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "Name" },
        { text: "On Every", value: "FrqncyIntr" },
        { text: "Frequency", value: "FrqncyType" },
        { text: "ExecTime", value: "ExecTime" },
        { text: "Next Execution", value: "NextDate" },
        { text: "Active", value: "Active" },
        { text: "Created", value: "created_at" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  computed: {
    mappedFrqncyType() {
      const valueToTextMap = {
        S: "Minutes",
        H: "Hours",
        D: "Days",
        W: "Weeks",
        M: "Months",
      };

      return (Frequency) => {
        if (valueToTextMap[Frequency]) {
          return valueToTextMap[Frequency];
        }
        // Return a default value or handle the case when Frequency doesn't match any value in the map.
        return "Unknown";
      };
    },
  },

  methods: {
    getAlertsData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/alerts`)
        .then((res) => {
          console.log(res);
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getAlertsData();
  },
};
</script>

<style scoped>
</style>